import cookies from "vue-cookies";
import router from "@/router";
import axios from "@/plugins/axios";
import { LOGIN } from "@/utils/api/auth";

export default {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      LOGIN(user)
        .then((response) => {
          // cookies.remove("token");
          const token = response?.data?.data?.authResponse?.token;
          const tokenExpire = response?.data?.data?.authResponse?.tokenExpireAt;
          console.log(tokenExpire);

          // console.log("token", tokenExpire);
          cookies.set("token", token);
          cookies.set("tokenExpire", tokenExpire);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          // if user has an acc but has not completed registration
          if (response?.data?.data?.userCategory === "CUSTOMER_REGISTRATION") {
            // console.log(user, "user");
            commit("setUserDetails", {
              email: user.username,
              password: user.secret,
            });
            dispatch(
              "notification/showAlert",
              {
                description: "Please complete your registration process",
                display: true,
                type: "error",
                buttonText: "Okay",
              },
              { root: true }
            );
            let step =
              response.data?.data?.customerRegistration
                ?.completedRegistrationSteps;
            // if user has completed these steps
            if (
              step.includes("SUBMIT_EMAIL") &&
              step.includes("VERIFY_EMAIL")
            ) {
              router.push("/bvn");
            } else if (step.includes("SUBMIT_EMAIL")) {
              router.push("/otp");
            }
            reject(response);
          } else if (response?.data?.data?.userCategory === "MERCHANT_USER") {
            dispatch(
              "notification/showAlert",
              {
                description:
                  "This account belongs to a merchant, Please register as a customer or login on your merchant application",
                display: true,
                type: "error",
                buttonText: "Okay",
              },
              { root: true }
            );
            reject(response);
          } else {
            // console.log("hi");
            commit("AUTH_SUCCESS", token);
            commit("setUserDetails", response.data.data.customer);
            commit("SET_TOKEN_EXPIRE", tokenExpire);
            router.push("/");
            resolve(response);
          }
        })
        .catch((error) => {
          // if (error?.response?.status === 404) {
          //   console.log(error, "err");
          // }
          if (error?.response?.data?.message) {
            dispatch(
              "notification/showAlert",
              {
                display: true,
                type: "error",
                description: error?.response?.data?.message,
              },
              { root: true }
            );
          }
          commit("AUTH_ERROR", error);
          reject(error);
        });
    });
  },
  logout({ commit }) {
    commit("AUTH_LOGOUT");
    cookies.remove("token");
    delete axios.defaults.headers.common["Authorization"];
    router.push("/login");
  },
  setPartnerName({ commit }, payload) {
    commit("SET_PARTNER_NAME", payload);
  },
};
