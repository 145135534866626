import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("formatMoney", function (value, currency = '₦') {
  let formattedNumber;
if (value === null || value === undefined) {
    formattedNumber = "₦0.00";
  } else {
    formattedNumber = new Intl.NumberFormat()
      .format(value)
      .replace(/(\.|,)00$/g, "");
  }

  return `${currency}${formattedNumber}`;
});

Vue.filter("formatDate", function (value) {
  if (!value) return null;
  return dayjs(value);
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});

Vue.directive("click-outside", {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); run the arg
      }
    };
    document.body.addEventListener("click", element.clickOutsideEvent);
  },
  unbind: function (element) {
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
});

Vue.filter("truncate", function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});
