import cookies from "vue-cookies";

export default {
  user: {},
  registrationId: "",
  token: cookies.get("token"),
  tokenExpire: cookies.get("tokenExpire"),
  status: "",
  currentPartnerName: ""
};
