<template>
  <aside class="h-full sidebar">
    <div class="sidebar-content mt-8">
      <div class="nav-item pl-8 mt-6">
        <div
          class="main-head flex align-items justify-between cursor-pointer"
          @click="showCustomers = !showCustomers"
        >
          <p class="text-lightPurple-2">Customers</p>
          <img
            class="h-3 my-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1650622183/13863747411556280997_aifldl.svg"
            alt=""
          />
        </div>
        <!-- customer nav options -->
        <AnimateHeight>
          <div
            class="bg-white rounded px-2 py-4 menu-sub mt-2"
            v-if="showCustomers"
          >
            <div>
              <router-link to="/" class="flex items-center pl-2">
                <p class="text-lightPurple-2">All Customers</p>
              </router-link>
            </div>

            <div>
              <router-link
                to="/verify-employment"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Verify employment</p>
              </router-link>
            </div>
            <div>
              <router-link
                to="/customers/company"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Company</p>
              </router-link>
            </div>
            <div>
              <router-link
                to="/verify-bank"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Bank Statement</p>
              </router-link>
            </div>
          </div>
        </AnimateHeight>
      </div>
      <div class="nav-item pl-8 mt-6">
        <div
          class="main-head flex align-items justify-between cursor-pointer"
          @click="showMerchant = !showMerchant"
        >
          <p class="text-lightPurple-2">Merchants</p>
          <img
            class="h-3 my-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1650622183/13863747411556280997_aifldl.svg"
            alt=""
          />
        </div>
        <AnimateHeight>
          <div
            class="bg-white rounded px-2 py-4 menu-sub mt-2"
            v-if="showMerchant"
          >
            <div>
              <router-link to="/merchants" class="flex items-center pl-2">
                <p class="text-lightPurple-2">All Merchants</p>
              </router-link>
            </div>

            <div>
              <router-link
                to="/merchants/categories"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Merchant Categories</p>
              </router-link>
            </div>
          </div>
        </AnimateHeight>
      </div>
      <div class="nav-item pl-8 mt-6">
        <div
          class="main-head flex align-items justify-between cursor-pointer"
          @click="showPayment = !showPayment"
        >
          <p class="text-lightPurple-2">Payments & Purchases</p>
          <img
            class="h-3 my-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1650622183/13863747411556280997_aifldl.svg"
            alt=""
          />
        </div>
        <AnimateHeight>
          <div
            class="bg-white rounded px-2 py-4 menu-sub mt-2"
            v-if="showPayment"
          >
            <div>
              <router-link to="/purchases" class="flex items-center pl-2">
                <p class="text-lightPurple-2">Purchases</p>
              </router-link>
            </div>

            <div>
              <router-link
                to="/purchases/payments"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">All Payments</p>
              </router-link>
            </div>
          </div>
        </AnimateHeight>
      </div>
      <div class="nav-item pl-8 mt-6">
        <div
          class="main-head flex align-items justify-between cursor-pointer"
          @click="showPartners = !showPartners"
        >
          <p class="text-lightPurple-2">Partners</p>
          <img
            class="h-3 my-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1650622183/13863747411556280997_aifldl.svg"
            alt=""
          />
        </div>
        <!-- partner nav options -->
        <AnimateHeight>
          <div
            class="bg-white rounded px-2 py-4 menu-sub mt-2"
            v-if="showPartners"
          >
            <div>
              <router-link
                to="/partner-registration"
                class="flex items-center pl-2"
              >
                <p class="text-lightPurple-2">Partner Registration</p>
              </router-link>
            </div>

            <div>
              <router-link to="/partners" class="flex items-center pl-2 mt-4">
                <p class="text-lightPurple-2">All Partners</p>
              </router-link>
            </div>
          </div>
        </AnimateHeight>
        
  
      </div>
      <div class="nav-item pl-8 mt-6">
        <div
          class="main-head flex align-items justify-between cursor-pointer"
          @click="showWire = !showWire"
        >
          <p class="text-lightPurple-2">Wire</p>
          <img
            class="h-3 my-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1650622183/13863747411556280997_aifldl.svg"
            alt=""
          />
        </div>
        <AnimateHeight>
          <div
            class="bg-white rounded px-2 py-4 menu-sub mt-2"
            v-if="showWire"
          >
            <div>
              <router-link to="/wire/currency-mappings" class="flex items-center pl-2">
                <p class="text-lightPurple-2">Currency mappings</p>
              </router-link>
            </div>

            <div>
              <router-link
                to="/wire/account"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Accounts</p>
              </router-link>
            </div>
            <div>
              <router-link
                to="/wire/payments"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Payments</p>
              </router-link>
            </div>
            <div>
              <router-link
                to="/wire/rate-adjustment"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Rate Discount</p>
              </router-link>
            </div>
            <div>
              <router-link
                to="/wire/registration"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Registration</p>
              </router-link>
            </div>
            <div>
              <router-link
                to="/wire/payouts"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Payouts</p>
              </router-link>
            </div>
            <div>
              <router-link
                to="/wire/owner-disputes"
                class="flex items-center pl-2 mt-4"
              >
                <p class="text-lightPurple-2">Owner disputes</p>
              </router-link>
            </div>
           
          </div>
        </AnimateHeight>
      </div>
      <div class="py-4 ml-8 pr-4">
        <router-link
          to="/users"
          class="flex items-center py-2"
        >
          <p class="text-lightPurple-2">User management</p>
        </router-link>
    </div>
    </div>
  </aside>
</template>
<script>
  import AnimateHeight from "@/UI/AnimateHeight";
  export default {
    components: {
      AnimateHeight,
    },
    data: () => ({
      showPartners: false,
      showCustomers: false,
      showMerchant: false,
      showPayment: false,
      showWire: false
    }),
  };
</script>
<style scoped>
  .sidebar {
    overflow: auto;
  }
  .sidebar-content a.router-link-exact-active {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: #ebe3ff;
    /* animation: slideInRight 0.3s linear; */
  }
  p {
    transition: all 0.3s ease-out;
  }
  .sidebar-content a.router-link-exact-active p {
    color: black;
    font-weight: 600;
  }
  .sidebar-content a.router-link-active p {
    color: black;
    font-weight: 600;
  }
  .nav-item {
    /* background-color: #ebe3ff; */
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-right: 3rem;
    transition: all 0.3s ease-out;
    /* display: inline; */
  }

  .sidebar::-webkit-scrollbar {
    width: 5px !important;
  }
  .sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d3d3d3;
    border-radius: 10px;
  }
  .sidebar::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }
  .menu-sub p {
    font-size: 14px;
    padding: 0.2rem 0;
  }
  @media (max-width: 768px) {
    .sidebar {
      display: none;
    }
  }

  @keyframes slideInRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
</style>
