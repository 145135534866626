<template>
  <div id="app">
    <Toast />
    <AlertBox />
    <Loader />
    <CreatePartner />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import { AlertBox, Toast } from "@/UI/Notification";
const default_layout = "default";
import Loader from "@/UI/Loader";
export default {
  components: {
    Toast,
    AlertBox,
    Loader,
    CreatePartner: () => import("@/components/CreatePartner"),
  },
  computed: {
    // dynamically render different layouts based on routes meta property
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    handleTransition() {
      return this.layout === "auth-layout" ? "slideX" : null;
    },
  },
};
</script>
<style>
@import "~@/assets/main.css";
@import "~@/assets/styles/animation.css";
#app {
  font-family: "Aventa", Helvetica;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
}
</style>
