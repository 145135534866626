import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import "@/plugins/filters";
import Auth from "@/Layouts/auth.vue";
import Default from "@/Layouts/default.vue";
import Card from "@/UI/Card";
import Skeleton from "@/UI/Skeleton";
import axios from "./plugins/axios";
import VueCookies from "vue-cookies";
import "./plugins/filters";
import 'remixicon/fonts/remixicon.css';


Vue.component("auth-layout", Auth);
Vue.component("default-layout", Default);
Vue.component("Card", Card);
Vue.component("Skeleton", Skeleton);
Vue.config.productionTip = false;

const token = VueCookies.get("token");
// console.log("token", token);
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
